.menu-container {
  background-image: url('./images/slide.jpg'); /* Set the background image URL with the correct path */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 0;
  font-family: Prompt, Helvetica, Arial, Sans-Serif, serif;
}


.menu-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.menu-container li {
  margin: 0 10px;
  line-height: 2;
}

.menu-container a {
  text-decoration: none;
  color: #cccccc;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5em;
  letter-spacing: 0px;
  text-transform: none;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.menu-container a:hover {
  color: #ffffff;
}
