.login {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login label {
  font-weight: bold;
  margin-bottom: 5px;
}

.login input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login button:hover {
  background-color: #0056b3;
}

.login .error {
  color: red;
  margin-top: 10px;
}
